










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IScenarioTopic } from '@/interfaces';

@Component
export default class ScenarioTopicsTab extends Vue {
  @Prop() public topicsIn!: IScenarioTopic[];
  @Watch('topicsIn') onQuestionInChanged() {
    this.topics = this.topicsIn;
  }

  public topics = [{}];
  public mounted() {
    this.topics = this.topicsIn;
  }
  public headerTooltips = {
    main_centroid:
      `The main topic clusters among people who picked
      the corresponding scenario as the most likely
      one. Click to expand to see further topics.`
  };
  public headers = [
    { 
      text: 'Scenarios',
      align: 'start',
      sortable: true,
      value: 'scenario',
      width: '15%',
    },
    {
      text: 'Main topics',
      sortable: false,
      value: 'main_centroid',
    },
    { text: '', value: 'data-table-expand' },
  ];
}
