
































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IEstimate, IQuestionData, ISessionData, IContract, IQuestionVideoCreate, QuestionTypes, IQuestionImage, IScenarioData } from '@/interfaces';
import { 
  dispatchUpdateQuestion,
  dispatchDeleteQuestion,
  dispatchGetSessionData,
  dispatchDeleteQuestionImage,
  dispatchUpdateQuestionImage,
  dispatchUpdateContract,
  dispatchCreateQuestionVideo,
} from '@/store/sessionData/actions';
import { commitAddNotification } from '@/store/main/mutations';
import { leadingScenarioData } from '@/components/utils';
import { capitalize } from '@/components/utils';
import {
  readQuestionResponses,
} from '@/store/sessionData/getters';
import { readToken } from '@/store/main/getters';
import { apiUrl } from '@/env';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import store from '@/store';
import { api } from '@/api';

@Component({
  components: {
    'vue-dropzone': vue2Dropzone,
  },
})
export default class OverviewTab extends Vue {
  @Prop(Object) public questionIn!: IQuestionData;
  @Prop(Number) public responseCountIn!: number;
  @Prop(Object) public sessionData!: ISessionData;

  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
  }

  @Watch('questionIn') onQuestionInChanged() {
    this.question = this.questionIn;
  }

  public question = this.questionIn;
  public responseCount = this.responseCountIn;
  public selectedState = this.sessionData.state;
  public debounce = 0;
  public polling = 0;
  public pausedPolling = false;
  public contracts: IContract[] = [];
  public estimateLocationOptions = [{ state: 'before question', val: false}, { state: 'after question', val: true}]
  public imagesDialog = false;
  public videosDialog = false;
  public loading = false;
  public singleImageDialog = false;
  public imageCaptionDialog = false;
  public singleVideoDialog = false;
  public validVideoUrls = ['youtube.com', 'youtu.be', 'vimeo.com'];
  public embedCode='';
  
  public valid = true;
  public isFormValid = false;
  public imageShown: IQuestionImage = {
    id: '',
    name: '',
    caption: '',
    url: '',
  };
  
  rules = {
    required: value => !!value || 'Required.',
    counter: value => value.length <= 20 || 'Max 20 characters',
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid e-mail.';
    },
    url: (value) => this.isURL(value) || 'URL is not valid',
    validVideoUrl: (value) => this.isValidVideoUrl(value) || 'Video URL is not valid. Allowed links: ' + this.validVideoUrls.join(', ') + '',
  };

  public dropzoneUploading = false;
  
  public videosShown: IQuestionVideoCreate[] = [];
  
  public data() {
    return {
      attrs: '',
      on: '',
    };
  }

  public dropzoneOptions = {
    url: `${apiUrl}/api/v1/questions/upload_question_image`,
    headers: {
      Authorization: `Bearer ${readToken(this.$store)}`,
    },
    paramName: function(n) {
      return 'files';
    },
    includeStyling: false,
    previewsContainer: false,
    thumbnailWidth: 250,
    thumbnailHeight: 140,
    uploadMultiple: true,
    parallelUploads: 20,
    success: function(file, response) {
      // Success
    },
  };  

  public getEstimate() {
    let estimates: IEstimate[];
    if (Array.isArray(this.question.data)) {
      estimates = leadingScenarioData(this.question.data as IScenarioData[]).estimates;
    }
    else {
      estimates = this.question.data.estimates;
    }
    return (estimates[estimates.length - 1].estimate * 100).toFixed(0);
  }

  public leadingContract() {
    if (Array.isArray(this.question.data)) {
      return leadingScenarioData(this.question.data as IScenarioData[]).contract.text;
    }
  }

  public async updateQuestion() {
    await dispatchUpdateQuestion(this.$store, {
      sessionId: this.$route.params.id,
      questionId: this.question.id,
      question: {
        title: this.question.title,
        context: this.question.context,
        contracts: this.contracts.filter(el => el.text != null && el.text !== ''),
        show_estimate_after: this.question.show_estimate_after,
        show_estimate: this.question.show_estimate,
      }
    });
  }

  public debounceUpdateQuestion() {
    clearTimeout(this.debounce);
    this.pausedPolling = true;
    this.debounce = setTimeout(() => {
      this.updateQuestion();
      this.pausedPolling = false;
    }, 1000);
  }

  public questionHasImages() {
    return this.question.images?.length;
  }
  
  public questionHasVideos() {
    return this.question.videos?.length;
  }

  uploadComplete(response) {
    this.dropzoneUploading = false;
  }

  beforeUpload(file, xhr, formData) {
    formData.append('question_id', this.question.id);
    this.dropzoneUploading = true;
  }

  async uploadSuccess() {
    // TODO(taha): Update only necessary data.
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    commitAddNotification(this.$store, {
      content: 'Image Uploaded Successfully.',
      color: 'success'
    });
  }

  public onClickEditImages() {
    this.imagesDialog = true;
  }

  public onClickAddImages() {
    this.imagesDialog = true;
  }
  
  public onClickAddVideos() {
    this.videosDialog = true;
  }
  
  public onClickEditVideos() {
    this.videosDialog = true;
  }

  public onShowImageDialog(image: IQuestionImage) {
    this.imageShown = {
      id: image.id,
      name: image.name,
      caption: image.caption,
      url: image.url,
    };
    this.singleImageDialog = true;
  }

  public addVideo(question: IQuestionData) {
    if(this.question.videos != undefined) {
      this.question.videos.push(
        {
          name: '',
          caption: '',
          embed_code: '',
          question_id: this.question.id
        });
    }
  }

  public async saveVideo() {
    if(this.question.videos != undefined) {
      await dispatchCreateQuestionVideo(this.$store, {
        sessionId: this.$route.params.id,
        questionId: this.question.id,
        questionVideos: this.question.videos
      });
      await dispatchGetSessionData(store, { id: this.$route.params.id });
    }
    this.videosDialog = false;
  }

  public async onDeleteVideo(index: number) {
    this.question.videos && this.question.videos.splice(index, 1);
  }

  public async getVideos() {
    return  this.question.videos;
  }

  public isURL(str: string) {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  public getEmbedCode(embed_code: string): string {
    let video_id ='';
    if(embed_code.startsWith('https://www.youtube.com')) {
      video_id = embed_code.split('v=')[1];
      return 'https://www.youtube.com/embed/${video_id}?enablejsapi=1'.replace('${video_id}', video_id);
    }
    if(embed_code.startsWith('https://youtu.be')) {
      video_id = embed_code.split('/')[3];
      return 'https://www.youtube.com/embed/${video_id}?enablejsapi=1'.replace('${video_id}', video_id);
    }
    else if(embed_code.startsWith('https://vimeo.com')) {
      video_id = embed_code.substring(embed_code.lastIndexOf('/') + 1);
      return 'https://player.vimeo.com/video/${video_id}?title=0&byline=0&portrait=0'.replace('${video_id}', video_id);
    }
    else {
      return embed_code;
    }
  }

  public isValidVideoUrl(value: string) {
    for (const url of this.validVideoUrls) {
      if (value.includes(url)) {
        return true;
      }
    }
    return false;
  }

  public onCloseImageDialog() {
    this.singleImageDialog = false;
  }

  public async onDeleteImage(id: string) {
    await dispatchDeleteQuestionImage(this.$store, {
      sessionId: this.$route.params.id,
      questionImageId: id
    });
    await dispatchGetSessionData(store, { id: this.$route.params.id });
  }

  public onShowImageCaptionDialog(image: IQuestionImage) {
    this.imageShown = {
      id: image.id,
      name: image.name,
      caption: image.caption,
      url: image.url
    };
    this.imageCaptionDialog = true;
  }

  async saveImageCaption() {
    await dispatchUpdateQuestionImage(this.$store, {
      sessionId: this.$route.params.id,
      questionImageId: this.imageShown.id,
      questionImage: {
        caption: this.imageShown.caption
      }
    });
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    this.imageCaptionDialog = false;
  }

  public onCloseImageCaptionDialog() {
    this.imageCaptionDialog = false;
  }

  public getQuestionLabel(question_type: string) {
    if(question_type == 'open') {
      return `${capitalize(question_type)}-ended Question`;
    } else {
      return `${capitalize(question_type)} Question`;
    }
  }

  public getEstimateLocation(question: IQuestionData) {
    if (question.show_estimate_after) {
      return 'after question';
    }
    else {
      return 'before question';
    }
  }

  public questionResponseCount(questionId: string) {
    const responses = readQuestionResponses(this.$store)(questionId);
    return responses ? responses.participants_count : 0;
  }

  public scenarioQuestionDialog = false;
  public openScenarioQuestionDialog() {
    this.scenarioQuestionDialog = true;
    this.contracts = [];
    if (Array.isArray(this.question.data)) {
      this.question.data.forEach((scenario) => {
        this.contracts.push({
          text:scenario.contract.text,
          contract_uuid:scenario.contract.contract_uuid,
          question_id:scenario.contract.question_id,
          language_id:scenario.contract.language_id
        });
      });
    }
  }

  public nominalQuestionDialog = false;
  public openNominalQuestionDialog() {
    this.nominalQuestionDialog = true;
    this.contracts = [];
    if (Array.isArray(this.question.data)) {
      this.question.data.forEach((scenario) => {
        this.contracts.push({
          text:scenario.contract.text,
          contract_uuid:scenario.contract.contract_uuid,
          question_id:scenario.contract.question_id,
          language_id:scenario.contract.language_id
        });
      });
    }
  }

  public resetContracts() {
    this.contracts = [];
  }

  public async closeScenarioQuestionDialog() {
    this.scenarioQuestionDialog = false;
    this.resetContracts();
  }

  public async closeNominalQuestionDialog() {
    this.nominalQuestionDialog = false;
    this.resetContracts();
  }

  public async removeQuestion(question) {
    await dispatchDeleteQuestion(this.$store, {
      sessionId: this.$route.params.id,
      questionId: question.id,
    });
  }

  public async editScenarios() {
    if (
      (this.question.type == QuestionTypes.scenario && this.contracts.length > 2) ||
      (this.question.type == QuestionTypes.nominal && this.contracts.length > 1)
    ) {
      this.scenarioQuestionDialog = false;
      this.nominalQuestionDialog = false;
      await dispatchUpdateContract(this.$store, this.contracts);
      await dispatchGetSessionData(store, { id: this.$route.params.id });
      this.contracts = [];
      if (Array.isArray(this.question.data)) {
        this.question.data.forEach((scenario) => {
          this.contracts.push({
            text:scenario.contract.text,
            contract_uuid:scenario.contract.contract_uuid,
            question_id:scenario.contract.question_id,
            language_id:scenario.contract.language_id
          });
        });
      }
    } else {
      const field = this.$validator.fields.find({name: '0'});
      if (field) {
        this.$validator.errors.add({
          field: '0',
          msg: this.question.type == QuestionTypes.scenario ?
            'You must have at least 3 unique scenarios. Please fix them to continue' : 
            'You must have at least 2 unique options. Please fix them to continue',
        });
      }
    }
  }

  public language_ids = {
    'EN-GB': 1,
    'SV': 2,
    'ZH': 3,
    'FR': 4,
    'DE': 5,
    'ES': 6,
  }

  public addScenario() {
    this.contracts.push({
      text: '',
      question_id: this.questionIn.id,
      language_id: this.language_ids[this.sessionData.display_lang]
    });
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name == 'xs' ? { ['small']: true } : {};
  }

  get small_breakpoint() {
    return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm';
  }

  public generateScenarioButtonText = 'Generate Scenario';
  public scenarioLoading = false;
  public async generateScenario() {
    this.generateScenarioButtonText = 'Generating...';
    const token = readToken(this.$store);
    this.scenarioLoading = true;
    try {
      const contracts = this.contracts.map((contract) => contract.text);
      const response = await api.generateSingleScenario(token, {
        study_title: this.question.title,
        question_title: this.question.title,
        contracts: contracts,
      });
      const contract = {
        text: response.data,
        question_id: this.question.id,
        language_id: this.language_ids[this.sessionData.display_lang]
      };
      if (contracts[contracts.length - 1].length < 1) {
        this.contracts[this.contracts.length - 1] = contract;
      } else {
        this.contracts.push(contract);
      }
    } catch (error) {
      commitAddNotification(this.$store, {
        content: 'Failed to generate scenario',
        color: 'error',
      });
    }
    this.generateScenarioButtonText = 'Generate Scenario';
    this.scenarioLoading = false;
  }
}
