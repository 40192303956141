






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { IQuestionCreate, QuestionTypes, IContract, IBinarySuggestion, IBinarySuggestionData} from '@/interfaces';
import { api } from '@/api';
import { Validator } from 'vee-validate';
import { readToken } from '@/store/main/getters';

Validator.extend('required', {
  getMessage: (field) => 'This field is required',
  validate: (value) => !!value,
});


@Component
export default class AddBinaryQuestion extends Vue {
  public valid = true;
  public questions: IQuestionCreate[] = [];
  public topic = '';
  public loadSuggestions = false
  public ownQuestion = '';
  public errorFetchingSuggestions = false;
  public currentQuestion = {
    title: '',
    context: '',
    type: QuestionTypes.binary,
    contracts: [],
  };
  
  public exitDisplay() {
    this.$emit('togglePredictionDialogue', false);
    this.resetQuestion();
  }
  public contracts: string[] = [];

  public binarySuggestions: IBinarySuggestion[] = [];

  public async prepBinaryQuestion() {
    this.currentQuestion.title = this.ownQuestion;
    this.$emit('moveCarousel');
  }

  public async setQuestionTitle(selectedSuggestion: string) {
    this.currentQuestion.title = selectedSuggestion;
    this.$emit('moveCarousel');
  }
  public async addQuestion() {
    if (await this.$validator.validateAll()) {
      this.$emit('pushQuestions', this.currentQuestion);
      this.$emit('togglePredictionDialogue', false);
      this.$emit('resetCarousel');
      this.resetQuestion();
      this.$validator.reset();
    }
  }

  public backToMenu() {
    this.resetQuestion();
    this.$validator.reset();
    this.$emit('resetCarousel');
  }

  public trimTopic() {
    this.topic = this.topic.trim();
  }
  public async fetchSuggestions() {
    this.resetBinarySuggestions();
    this.currentQuestion.title = '';
    this.trimTopic();
    if (this.topic.length > 0) {
      this.$validator.reset();
      const response = await this.actionGetBinarySuggestions(
        {topic: this.topic}
      );
      this.$emit('moveCarousel');
      const suggestions = response?.data;
      suggestions.forEach((i) => {
        this.binarySuggestions.push(
          {suggestion: i});
      });
    } 
    else {
      const field = 'topic';
      this.$validator.validate(field);
      this.$validator.reset;
    }
  }
  public async actionGetBinarySuggestions(input: IBinarySuggestionData) {
    this.loadSuggestions = true;
    const token = readToken(this.$store);
    try {
      const response = await api.getSuggestions(token, input);
      if (response.status==200) {
        this.loadSuggestions = false;
        return response;
      }
    } catch (error) {
      this.errorFetchingSuggestions == true;
    }
  }

  public backOneStep() {
    this.resetOwnQuestion();
    this.$emit('moveCarousel', false);
  }

  public resetQuestion() {
    this.currentQuestion = {
      title: '',
      context: '',
      type: QuestionTypes.binary,
      contracts: [],
    };
    this.resetBinarySuggestions();
    this.resetTopic();
  }

  public resetBinarySuggestions() {
    this.binarySuggestions = [];
  }

  public resetTopic() {
    this.topic = '';
  }

  public resetOwnQuestion() {
    this.ownQuestion = '';
  }
  beforeDestroy() { this.$validator.pause(); }
}
