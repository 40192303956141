

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { IQuestionData, INominalQuestionData } from '@/interfaces';

@Component({
  components: {
    VueApexCharts,
  }
})
export default class NominalQuestionGraphTab extends Vue {
  @Prop() public questionIn!: IQuestionData;
  @Prop(Number) public responseCountIn!: number;

  @Watch('questionIn') onQuestionInChanged() {
    this.update();
  }

  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
  }

  public responseCount = this.responseCountIn;
  public question = this.questionIn;

  mounted() {
    this.update();
  }

  get graphHeight() {
    return this.mobile ? '150%' : '350%';
  }

  get series() {
    return (this.question.data as INominalQuestionData[]).map(item => item.answer_count);
  }

  get chartOptions() {
    return {
      'labels': (this.question.data as INominalQuestionData[]).map(item => item.contract.text),
    };
  }

  public update() {
    this.question = this.questionIn;
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }
}

