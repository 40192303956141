



































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IQuestionData, IScenarioData } from '@/interfaces';
import { readLatestQuestionEstimates } from '@/store/sessionData/getters';
import { leadingScenarioData } from '@/components/utils';
import VueApexCharts from 'vue-apexcharts';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class QuestionSummaryTab extends Vue {
  @Prop(Object) public questionIn!: IQuestionData;
  @Prop(String) public questionType!: string;
  @Prop(String) public questionId!: string;
  @Prop(Number) public responseCountIn!: number;
  @Prop(Boolean) public isPublic!: boolean;
  @Watch('questionIn') onQuestionInChanged() {
    this.question = this.questionIn;
    this.updateDashBoardData();
  }
  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
    this.updateDashBoardData();
  }

  public question = this.questionIn;
  public responseCount = this.responseCountIn;
  public DateFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  public dashBoardData = {
    forReason: 'Not enough reasons available yet.',
    againstReason: 'Not enough reasons available yet.',
    avgChangeFor: '0',
    avgChangeAgainst: '0',
    responsiveness: '0'
  }

  public reasonsDataExists() {
    let dataAvailable;
    if (this.dashBoardData.forReason == 'Not enough reasons available yet.' && this.dashBoardData.againstReason == 'Not enough reasons available yet.') {
      dataAvailable = false;
    }
    else {
      dataAvailable = true;
    }
    return dataAvailable;
  }

  public data() {
    return {
      attrs: '',
      on: '',
    };
  }

  mounted() {
    this.updateDashBoardData();
  }

  public getChartOptions = (color, fontSize, estimateOffset) => {
    return {
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#323456',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: false,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: estimateOffset,
              fontSize: fontSize,
              fontWeight: 'bold',
              color: '#fff',
              background: '#75AE30',
            },
          },
          total: {
            show: true,
          },
        },
      },
      fill: {
        type: 'solid',
        colors: [color],
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
    };
  };

  public getDisagreement() {
    return (this.question.disagreement * 100).toFixed(0);
  }

  public updateDashBoardData() {
    const data = Array.isArray(this.question.data) ? leadingScenarioData(this.question.data as IScenarioData[]) : this.question.data;
    this.dashBoardData.responsiveness = (this.question.responsiveness * 100).toFixed(0);
    if (data.for_reason) {
      this.dashBoardData.forReason = data.for_reason.reason;
      this.dashBoardData.avgChangeFor = (data.for_reason.avg_change * 100).toFixed(0);
    }
    if (data.against_reason) {
      this.dashBoardData.againstReason = data.against_reason.reason;
      this.dashBoardData.avgChangeAgainst = (data.against_reason.avg_change * 100).toFixed(0);
    }
  }

  public getEstimate() {
    let estimates;
    if (Array.isArray(this.question.data)) {
      estimates = leadingScenarioData(this.question.data as IScenarioData[]).estimates;
    }
    else {
      estimates = this.question.data.estimates;
    }
    return (estimates[estimates.length - 1].estimate * 100).toFixed(0);
  }

  public leadingContract() {
    if (Array.isArray(this.question.data)) {
      return leadingScenarioData(this.question.data as IScenarioData[]).contract.text;
    }
  }

  public latestEstimate(questionId: string) {
    return readLatestQuestionEstimates(this.$store)(questionId);
  }
  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }
  get tablet() {
    return this.$vuetify.breakpoint.name === 'sm';
  }
  get widetablet() {
    return this.$vuetify.breakpoint.name === 'md';
  }
}
