








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import JsonCSV from 'vue-json-csv';
import {
  readQuestionResponseData,
  readSessionData,
} from '@/store/sessionData/getters';
import {
  readHasAdminAccess,
  readToken,
} from '@/store/main/getters';
import { dispatchGetQuestionResponses } from '@/store/sessionData/actions';
import store from '@/store';
import { api } from '@/api';

@Component({
  components: {
    JsonCSV,
  }
})
export default class NominalQuestionResponseTab extends Vue {
  @Prop(String) public questionId!: string;
  @Prop(Boolean) public isPublic!: boolean;
  @Prop(Number) public responseCountIn!: number;

  public responseCount = this.responseCountIn;
  public pageNumber = 1;
  public pageLength = 5;
  public pageStart = 0;
  public search = '';

  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
  }

  public headers = [
    {
      text: this.mobile ? 'Number:' : 'No.',
      value: 'index',
      sortable: false,
      width: '5%',
      show: true,
    },
    { 
      text: this.mobile ? 'Response:' : 'Response',
      sortable: false,
      value: 'contract',
      width: '95%',
      show: true,
    }
  ];

  public btnDisabled = (() => {
    return false;
  })

  public async downloadCSV() {
    const token = readToken(this.$store);
    const response = await api.getQuestionResponses(token, this.questionId, 9999, 1, '', [], true);
    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'responses.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }

  public async changeSearchText(value: string) {
    if (value.length > 0 && value.length < 3) return;

    this.search = value;
    await dispatchGetQuestionResponses(store, {
      questionId: this.questionId,
      pageLength: this.pageLength,
      pageNumber: this.pageNumber,
      search: this.search
    });
  }

  public async paginationChanged(pagination: { page: number; itemsPerPage: number; pageStart: number; pageStop: number; pageCount: number; itemsLength: number; search: string }) {
    this.pageStart = pagination.pageStart;
    this.pageNumber = pagination.page;
    this.pageLength = pagination.itemsPerPage;
    await dispatchGetQuestionResponses(store, {questionId: this.questionId, pageLength: pagination.itemsPerPage, pageNumber: pagination.page, search: this.search});
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get sessionData() {
    return readSessionData(this.$store);
  }

  get responses() {
    return readQuestionResponseData(this.$store)(this.questionId)?.participants ?? [];
  }

  // CSV-download
  public csvTitle = 'Responses.csv';
  public csvLabels = {
    contract: 'contract',
  }
  public csvFields = ['contract',];
  public csvFieldsAdmin = ['contract'];
  
  get mobile () {
    return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
  }
}
