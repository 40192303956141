




































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import JsonCSV from 'vue-json-csv';
import {floatToPercentage} from '@/components/utils';
import {readQuestionResponseData, readSessionData,} from '@/store/sessionData/getters';
import {readHasAdminAccess, readToken,} from '@/store/main/getters';
import {dispatchGetQuestionResponses} from '@/store/sessionData/actions';
import { api } from '@/api';
import store from '@/store';

@Component({
  components: {
    JsonCSV,
  }
})
export default class SessionResponsesTab extends Vue {
  @Prop(String) public questionId!: string;
  @Prop(Boolean) public isScenario!: boolean;
  @Prop(Boolean) public isPublic!: boolean;
  @Prop(Number) public responseCountIn!: number;

  public loading = true;

  public responseCount = this.responseCountIn;
  public pageNumber = 1;
  public pageLength = 5;
  public pageStart = 0;
  public search = '';

  public InfoScoreFilterValues = [0, 1, 2, 3, 4];
  public selectedInfoScoreValues = [0, 1, 2, 3, 4];

  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
  }

  public formatter(value) {
    return floatToPercentage(value);
  }
  public headers = [
    {
      text: this.mobile ? 'Number:' : 'No.',
      value: 'index',
      width: '5%',
      sortable: false,
      show: true,
    },
    {
      text: this.mobile ? 'Scenario:' : 'Scenario',
      align: 'start',
      sortable: false,
      value: 'contract',
      width: '10%',
      show: this.isScenario
    },
    {
      text: this.mobile ? 'Estimate:' : 'Estimate',
      sortable: false,
      value: 'p_estimate',
      width: '10%',
      show: true,
    },
    {
      text: this.mobile ? 'Reason:' : 'Reason',
      sortable: false,
      value: 'reason',
      show: true,
    },
    {
      text: this.mobile ? 'Revised estimate:' : 'Revised estimate',
      sortable: false,
      value: 'p_estimate_update',
      width: '10%',
      show: !this.mobile,
    },
    {
      text: '',
      value: 'data-table-expand',
      show: true,
    },
  ];

  public btnDisabled = (() => {
    return false;
  })

  public async downloadCSV() {
    const token = readToken(this.$store);
    const response = await api.getQuestionResponses(token, this.questionId, 9999, 1, '', [], true);
    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'responses.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }

  public async clearSearchText() {
    this.search = '';
    await dispatchGetQuestionResponses(store, {
      questionId: this.questionId,
      pageLength: this.pageLength,
      pageNumber: this.pageNumber,
      search: ''
    });
  }

  public async changeSearchText(value: string) {
    if (value && (value.length == 1 || value.length == 2)) return;
    this.search = value;
    await dispatchGetQuestionResponses(store, {
      questionId: this.questionId,
      pageLength: this.pageLength,
      pageNumber: this.pageNumber,
      search: this.search
    });
  }

  public async paginationChanged(pagination: { page: number; itemsPerPage: number; pageStart: number; pageStop: number; pageCount: number; itemsLength: number; search: string }) {
    this.loading = true;
    this.pageStart = pagination.pageStart;
    this.pageNumber = pagination.page;
    this.pageLength = pagination.itemsPerPage;
    await dispatchGetQuestionResponses(store, {
      questionId: this.questionId,
      pageLength: this.pageLength,
      pageNumber: this.pageNumber,
      search: this.search,
      selectedScores: this.selectedInfoScoreValues
    });
    this.loading = false;
    const search_field = this.$refs.search as HTMLElement | null;
    search_field?.focus();
  }

  public async filterByScore(selectedItems) {
    this.selectedInfoScoreValues = selectedItems.sort();
    this.loading = true;
    await dispatchGetQuestionResponses(store, {
      questionId: this.questionId,
      pageLength: this.pageLength,
      pageNumber: this.pageNumber,
      search: this.search,
      selectedScores: this.selectedInfoScoreValues
    });
    this.loading = false;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get sessionData() {
    return readSessionData(this.$store);
  }

  get responses() {
    return readQuestionResponseData(this.$store)(this.questionId)?.participants ?? [];
  }

  get mobile () {
    return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
  }
}
