import { IScenarioData } from '@/interfaces';
import colors from 'vuetify/lib/util/colors';

export const binaryToolTip = (repReasons, dataPointIndex) => {
  let lowerRepReason;
  let upperRepReason;
  if (!repReasons[dataPointIndex]) {
    return '';
  }
  if (repReasons) {
    lowerRepReason = repReasons[dataPointIndex].lower;
    upperRepReason = repReasons[dataPointIndex].upper;
  }
  if (!lowerRepReason && !upperRepReason) {
    return '';
  }
  else if (lowerRepReason && !upperRepReason) {
    return (
      `<div class="q_tooltip">
        <p class="text-wrap">
          <span class="font-weight-bold">
            People who think it's highly unlikely or unlikely are saying:
          </span>
          <br>
          <span class="text--secondary">
            ${lowerRepReason.rep_reason}
          </span>
        </p>
      </div>`
    );     
  }
  else if (upperRepReason && !lowerRepReason) {
    return (
      `<div class="q_tooltip">
        <p class="text-wrap">
          <span class="font-weight-bold">
            People who think it's highly likely or likely are saying:
          </span>
          <br>
          <span class="text--secondary">
            ${upperRepReason.rep_reason}
          </span>
        </p>
      </div>`
    );     
  }
  return (
    `<div class="q_tooltip">
      <p class="text-wrap">
        <span class="font-weight-bold">
          People who think it's highly likely or likely are saying:
        </span>
        <br>
        <span class="text--secondary">
          ${upperRepReason.rep_reason}
        </span>
        <br>
        <br>
        <span class="font-weight-bold">
          And people who think it's highly unlikely or unlikely are saying:
        </span>
        <br>
        <span class="text--secondary">
          ${lowerRepReason.rep_reason}
        </span>
      </p>
    </div>`
  );
};

export const scenarioToolTip = (repReasons, dataPointIndex) => {
  let lowerRepReason;
  let upperRepReason;
  if (repReasons) {
    lowerRepReason = repReasons[dataPointIndex].lower;
    upperRepReason = repReasons[dataPointIndex].upper;
  }
  if (!lowerRepReason && !upperRepReason) {
    return '';
  }
  else if (lowerRepReason && !upperRepReason) {
    return (
      `<div class="q_tooltip">
        <p class="text-wrap">
          <span class="font-weight-bold">
            People who think "${lowerRepReason.contract}" is most likely are saying:
          </span>
          <br>
          <span class="text--secondary">
            ${lowerRepReason.rep_reason}
          </span>
        </p>
      </div>`
    );     
  }
  else if (upperRepReason && !lowerRepReason) {
    return (
      `<div class="q_tooltip">
        <p class="text-wrap">
          <span class="font-weight-bold">
            People who think "${upperRepReason.contract}" is most likely are saying:
          </span>
          <br>
          <span class="text--secondary">
            ${upperRepReason.rep_reason}
          </span>
        </p>
      </div>`
    );     
  }
  return (
    `<div class="q_tooltip">
      <p class="text-wrap">
        <span class="font-weight-bold">
          People who think "${upperRepReason.contract}" is most likely are saying:
        </span>
        <br>
        <span class="text--secondary">
          ${upperRepReason.rep_reason}
        </span>
        <br>
        <br>
        <span class="font-weight-bold">
          And people who think "${lowerRepReason.contract}" is most likely are saying:
        </span>
        <br>
        <span class="text--secondary">
          ${lowerRepReason.rep_reason}
        </span>
      </p>
    </div>`
  );
};

export const graphColors = [
  '#A3E581',
  '#FF9191',
  '#F20000',
  '#97C5DD',
  '#FFBD5B',
  '#FF7700',
  '#72289D',
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

export const leadingScenarioData = (data: IScenarioData[]) => {
  return data.reduce(
    (a, b) => a.estimates[a.estimates.length - 1].estimate > b.estimates[b.estimates.length - 1].estimate ? a : b
  );
};

export const floatToPercentage = (value: any) => {
  if (value != null) {
    return `${Math.round(value*100)}%`;
  } else {return '';}
};

export const chatColors = {
  header: {
    bg: '#414141',
    text: '#CCD9D9',
  },
  launcher: {
    bg: '#303931',
  },
  messageList: {
    bg: '#181818',
  },
  sentMessage: {
    bg: '#F8F9FA',
    text: '#181818'
  },
  receivedMessage: {
    bg: '#414141',
    text: '#CCD9D9',
  },
  userInput: {
    bg: '#181818',
    text: '#FFFFFF',
  }
};


export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};