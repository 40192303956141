






























































































































































import { Component, Vue } from 'vue-property-decorator';
import { IQuestionCreate, QuestionTypes } from '@/interfaces';
import { Validator } from 'vee-validate';
import AddBinaryQuestion from '@/components/AddBinaryQuestion.vue';
import AddScenarioQuestion from '@/components/AddScenarioQuestion.vue';
import AddOpenQuestion from '@/components/AddOpenEndedQuestion.vue';
import AddNominalQuestion from '@/components/AddNominalQuestion.vue';

Validator.extend('required', {
  getMessage: (field) => 'This field is required',
  validate: (value) => !!value,
});


@Component({
  components: {
    'add-binary-question': AddBinaryQuestion,
    'add-scenario-question': AddScenarioQuestion,
    'add-open-question': AddOpenQuestion,
    'add-nominal-question': AddNominalQuestion,
  },
})

export default class QuestionCarousel extends Vue {
  public addPredectionDialog = false;
  public valid = true;
  public questions: IQuestionCreate[] = [];
  public carousel = 0;
  public selectedType: QuestionTypes = QuestionTypes.binary;

  public types = [
    {
      text: 'Binary question',
      value: QuestionTypes.binary,
      bool: false
    },
    {
      text: 'Scenario question',
      value: QuestionTypes.scenario,
      bool: false
    },
    {
      text: 'Open question',
      value: QuestionTypes.open,
      bool: false
    },
  ];

  public async addQuestion(question) {
    this.questions.push(question);
    this.$emit('pushQuestions', question);
  }

  public resetCarousel() {
    this.carousel = 0;
  }

  public toggleQuestion(index: number) {
    if (index == 0) {
      this.selectedType = QuestionTypes.binary;
    } else if (index == 1) {
      this.selectedType = QuestionTypes.scenario;
    } else if (index == 2) {
      this.selectedType = QuestionTypes.open;
    } else if (index == 3) {
      this.selectedType = QuestionTypes.nominal;
    }
    this.moveCarousel(true);
  }

  public moveCarousel(value = true) {
    if (this.carousel >= 0) {
      if (value) {this.carousel++;
      } else {this.carousel--;}
    }
  }

  public resetSession() {
    this.questions = [];
    this.$validator.reset();
  }

  public togglePredictionDialogue(value: boolean) {
    this.addPredectionDialog = value;
    this.resetCarousel();
  }

  beforeDestroy() { this.$validator.pause(); }

  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

}
