































































import { Component, Vue } from 'vue-property-decorator';
import { QuestionTypes} from '@/interfaces';

@Component
export default class AddOpenEndedQuestion extends Vue {

  public exitDisplay() {
    this.$emit('togglePredictionDialogue', false);
    this.resetQuestion();
  }

  public currentQuestion = {
    title: '',
    context: '',
    type: QuestionTypes.open,
    contracts: [],
    randomize_qs: false,
  };


  beforeDestroy() { this.$validator.pause(); }

  public async addQuestion() {
    if (await this.$validator.validateAll()) {
      this.$emit('pushQuestions', this.currentQuestion);
      this.$emit('togglePredictionDialogue', false);
      this.$emit('resetCarousel');
      this.resetQuestion();
      await this.$validator.reset();
    }
  }

  public backToMenu() {
    this.resetQuestion();
    this.$validator.reset();
    this.$emit('resetCarousel');
  }

  public resetQuestion() {
    this.currentQuestion = {
      title: '',
      context: '',
      type: QuestionTypes.open,
      contracts: [],
      randomize_qs: false,
    };
  }
  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }
}

