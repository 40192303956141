var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-btn',_vm._b({attrs:{"id":"createStudyAddQuestion","outlined":""},on:{"click":function($event){return _vm.togglePredictionDialogue(true)}}},'v-btn',_vm.buttonSize,false),[_vm._v(" Add a question ")]),_c('v-dialog',{attrs:{"max-width":"900","persistent":""},model:{value:(_vm.addPredectionDialog),callback:function ($$v) {_vm.addPredectionDialog=$$v},expression:"addPredectionDialog"}},[_c('v-carousel',{attrs:{"show-arrows":false,"cycle":false,"hide-delimiters":"","touchless":""},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},[_c('v-carousel-item',[_c('v-card',{class:_vm.mobile ? 'pa-2' : 'pa-8',staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},attrs:{"height":"100%","width":"100%;"}},[_c('v-row',[_c('v-col',{attrs:{"align":"end"}},[_c('v-icon',{staticClass:"pa-4",on:{"click":function($event){return _vm.togglePredictionDialogue(false)}}},[_vm._v(" "+_vm._s("mdi-close")+" ")])],1)],1),_c('v-card-title',{staticClass:"justify-center pl-8 pr-8 pb-8 text-center"},[_vm._v(" What type of question would you like to ask? ")]),_c('v-row',{staticClass:"mt-md-6",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center align-center text-center py-0",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-2",class:{ 'on-hover': hover },staticStyle:{"border":"1px solid gray"},attrs:{"elevation":hover ? 16 : 2,"height":_vm.mobile ? '150' : '200',"width":_vm.mobile ? '200' : '150'},on:{"click":function($event){return _vm.toggleQuestion(0)}}},[_c('div',{staticClass:"ma-4"},[_c('h3',[_vm._v("Binary Question")]),_c('p',{staticClass:"text-caption font-weight-light"},[_vm._v(" Can be answered with a 'yes' or a 'no'. For example: \"Will X happen?\" ")])])])]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center align-center text-center py-0",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-4",class:{ 'on-hover': hover },staticStyle:{"border":"1px solid gray"},attrs:{"id":"createStudyScenarioQuestionCard","elevation":hover ? 16 : 2,"height":_vm.mobile ? '150' : '200',"width":_vm.mobile ? '200' : '150'},on:{"click":function($event){return _vm.toggleQuestion(1)}}},[_c('div',{staticClass:"ma-4"},[_c('h3',[_vm._v("Scenario Question")]),_c('p',{staticClass:"text-caption font-weight-light"},[_vm._v(" Asks about a number of possible scenarios. For example: \"What will happen: A, B, or C?\". ")])])])]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center align-center text-center py-0",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-4",class:{ 'on-hover': hover },staticStyle:{"border":"1px solid gray"},attrs:{"elevation":hover ? 16 : 2,"height":_vm.mobile ? '150' : '200',"width":_vm.mobile ? '200' : '150'},on:{"click":function($event){return _vm.toggleQuestion(2)}}},[_c('div',{staticClass:"ma-4"},[_c('h3',[_vm._v("Open Question")]),_c('p',{staticClass:"text-caption font-weight-light"},[_vm._v(" Collects open responses in free text. For example: \"What will happen?\". ")])])])]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center align-center text-center py-0",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-4",class:{ 'on-hover': hover },staticStyle:{"border":"1px solid gray"},attrs:{"id":"createStudyNominalQuestionCard","elevation":hover ? 16 : 2,"height":_vm.mobile ? '150' : '200',"width":_vm.mobile ? '200' : '150'},on:{"click":function($event){return _vm.toggleQuestion(3)}}},[_c('div',{staticClass:"ma-4"},[_c('h3',[_vm._v("Nominal Question")]),_c('p',{staticClass:"text-caption font-weight-light"},[_vm._v(" Demographic or other categorizing questions. For example: \"What is your gender?\" ")])])])]}}])})],1)],1)],1)],1),(_vm.selectedType=='binary' && _vm.carousel >= 1)?_c('add-binary-question',{on:{"pushQuestions":_vm.addQuestion,"resetCarousel":_vm.resetCarousel,"togglePredictionDialogue":_vm.togglePredictionDialogue,"moveCarousel":_vm.moveCarousel}}):_vm._e(),(_vm.selectedType=='scenario' && _vm.carousel >= 1)?_c('add-scenario-question',{on:{"pushQuestions":_vm.addQuestion,"resetCarousel":_vm.resetCarousel,"togglePredictionDialogue":_vm.togglePredictionDialogue}}):_vm._e(),(_vm.selectedType=='open' && _vm.carousel >= 1)?_c('add-open-question',{on:{"pushQuestions":_vm.addQuestion,"resetCarousel":_vm.resetCarousel,"togglePredictionDialogue":_vm.togglePredictionDialogue}}):_vm._e(),(_vm.selectedType=='nominal' && _vm.carousel >= 1)?_c('add-nominal-question',{on:{"pushQuestions":_vm.addQuestion,"resetCarousel":_vm.resetCarousel,"togglePredictionDialogue":_vm.togglePredictionDialogue}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }