




















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { dispatchOrderQuestions } from '@/store/sessionData/actions';
import { IQuestionData } from '@/interfaces';
import { readSessionData } from '@/store/sessionData/getters';
import draggable from 'vuedraggable';
import store from '@/store';

@Component({
  components: {
    'draggable': draggable
  }
})
export default class OrderQuestionsDialog extends Vue {
  @Prop() public show = false;
  @Watch('show') onShowChanged() {
    this.orderedQuestions = [ ...this.sessionQuestions ];
  }
  public orderedQuestions = [] as IQuestionData[];

  get sessionQuestions() {
    return readSessionData(this.$store).questions_with_data;
  }

  @Emit()
  public async saveOrder() {
    await dispatchOrderQuestions(
      store,
      { sessionId: this.$parent.$route.params.id, questions: [ ...this.orderedQuestions ] }
    );
  }

  @Emit()
  public cancel() {
    return;
  }

}
