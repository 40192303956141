import { render, staticRenderFns } from "./NominalQuestionGraphTab.vue?vue&type=template&id=5e7152cc"
import script from "./NominalQuestionGraphTab.vue?vue&type=script&lang=ts"
export * from "./NominalQuestionGraphTab.vue?vue&type=script&lang=ts"
import style0 from "./NominalQuestionGraphTab.vue?vue&type=style&index=0&id=5e7152cc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTabItem})
