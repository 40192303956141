



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { IQuestionData, IScenarioData } from '@/interfaces';
import { graphColors, binaryToolTip, scenarioToolTip } from '@/components/utils';

@Component({
  components: {
    VueApexCharts,
  }
})
export default class QuestionGraphTab extends Vue {
  @Prop() public questionIn!: IQuestionData;
  @Prop(Number) public responseCountIn!: number;

  @Watch('questionIn') onQuestionInChanged() {
    this.update();
  }

  @Watch('responseCountIn') onResponseCountChanged() {
    this.responseCount = this.responseCountIn;
  }

  public responseCount = this.responseCountIn;
  public question = this.questionIn;
  public chartOptions = {};

  mounted() {
    this.update();
  }

  get graphHeight() {
    return this.mobile ? '150%' : '350%';
  }

  get repReasons() {
    const data = this.question.data;
    if (Array.isArray(data)) {
      const estimates = (data[0] as IScenarioData).estimates;
      return estimates.map((est) => {
        return {
          lower: est.lower_rep_reason,
          upper: est.upper_rep_reason
        };
      });
    }
    else {
      const estimates = data.estimates;
      return estimates.map((est) => {
        return {
          lower: est.lower_rep_reason,
          upper: est.upper_rep_reason
        };
      });
    }
  }

  public getSeries() {
    if (Array.isArray(this.question.data)) {
      return (this.question.data as IScenarioData[]).map((scenario) => {
        const estimates = scenario.estimates.map((est) => est.estimate);
        return {
          name: scenario.contract.text,
          data: estimates.length > 1 ? estimates : [],
        };
      });
    }
    else {
      const estimates = this.question.data.estimates.map((est) => est.estimate);
      return [
        {
          name: 'Estimate',
          data: estimates.length > 1 ? estimates : [],
        },
      ];
    }
  }

  public update() {
    this.question = this.questionIn;
    this.chartOptions = this.getChartOptions(
      this.questionIn.data,
      this.repReasons,
    );
  }

  getCategories = (data) => {
    const created = Array.isArray(data) ? data[0].created : data.created;
    let categories = [];
    if (created.length > 1) {
      categories = created.map((val) => new Date(val).toLocaleTimeString(
        'en-US',{ month: 'short', hour: '2-digit', minute: '2-digit' })
      );
    }
    return categories;
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  public getChartOptions = (data, repReasons) => {
    return {
      grid: {
        show: false,
      },
      colors: graphColors,
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      shared: false,
      tooltip: {
        custom: this.mobile ? undefined : function({series, seriesIndex, dataPointIndex}) {
          return (
            series.length > 1 ? scenarioToolTip(repReasons, dataPointIndex)
              :
              binaryToolTip(repReasons, dataPointIndex)
          );
        },
        enabled: true,
        theme: 'dark',
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          show: !this.mobile,
          style: {
            colors: '#fff',
          },
        },
        categories: this.getCategories(data)
      },
      legend: {
        fontSize: '14px',
        showForSingleSeries: true,
        labels: {
          colors: '#fff',
          useSeriesColors: false
        },
        tooltipHoverFormatter: function(val, opts) {
          const hover = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
          if (hover) {
            return val + ': ' + (hover * 100).toFixed(0) + '%';
          }
        }
      },
      yaxis: {
        min: 0,
        max: 1,
        title: {
          text: 'Estimate',
          style: {
            color: '#fff',
          },
        },
        labels: {
          style: {
            colors: '#fff',
          },
          formatter: (val) => {
            return val ? val.toFixed(2) : null;
          },
        },
      },
    };
  }
}

