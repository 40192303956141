







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IBinaryTopic } from '@/interfaces';
import { floatToPercentage } from '@/components/utils';

@Component
export default class OpenEndedQuestionTopicsTab extends Vue {
  @Prop() public topicsIn!: IBinaryTopic[];
  @Watch('topicsIn') onQuestionInChanged() {
    this.topics = this.topicsIn;
  }

  public topics = [{}];
  public mounted() {
    this.topics = this.topicsIn;
  }

  public formatter(value) {
    return floatToPercentage(value);
  }

  public headerTooltips = {
    centroid_reason: 'The main topic clusters among reasons offered by participants for their estimates. Click to expand to see other members of the relevant cluster.',
  };
  public headers = [
    {
      text: 'Topic cluster',
      align: 'start',
      sortable: true,
      value: 'centroid_reason',
    },
    { text: '', value: 'data-table-expand' },
  ];
}
