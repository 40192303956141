




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { IBinaryData, IQuestionData, IScenarioData, ISessionData, INominalQuestion } from '@/interfaces';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component({
  components: {
    VueApexCharts,
  }
})
export default class EstimatesTabNew extends Vue {
  @Prop() public questionIn!: IQuestionData;
  @Prop(Number) public responseCountIn!: number;
  @Prop() public nominalQuestionsIn!: INominalQuestion[] | undefined;
  @Prop() public sessionDataIn!: ISessionData;

  @Watch('questionIn') onQuestionInChanged() {
    this.update();
  }

  public responseCount = this.responseCountIn;
  public question = { ...this.questionIn };
  public sessionData = { ...this.sessionDataIn };
  public chartOptions = {};
  public selectedAgeValues = [];
  public selectedGenderValues = [];
  public nominal_questions = this.nominalQuestionsIn;
  public nominal_filters: number[][] = [];
  public filtersLoading = false;

  mounted() {
    this.update();
    this.nominal_questions = this.nominalQuestionsIn;
    this.clearFilter();
  }

  public clearFilter() {
    this.nominal_filters = [];
    for (const x in this.nominal_questions) {
      this.nominal_filters.push([]);
    }
  }

  public async applyFilter() {
    if (this.nominal_questions === undefined) return;
    const filters: { question_id: string; contracts: string[] }[] = [];
    for (let i = 0; i < this.nominal_filters.length; i++) {
      const contracts: string[] = [];
      for (const filter of this.nominal_filters[i]) {
        contracts.push(this.nominal_questions[i].contracts[filter].contract_uuid);
      }
      filters.push(
        {
          'question_id': this.nominal_questions[i].id,
          'contracts': contracts
        }
      );
    }
    const token = readToken(this.$store);
    this.filtersLoading = true;
    const response = await api.getEstimatesFiltered(token, this.question.id, filters);
    this.filtersLoading = false;
    if (response.status === 200) {
      this.question.data = response.data;
    }
  }

  get graphHeight() {
    return this.mobile ? '150%' : '350%';
  }

  get selectedState() {
    return this.sessionData.state;
  }

  public getSeriesScenario() {
    const data = this.question.data as IScenarioData[];
    const l = data.map((scenario) => {
      const estimates = scenario.estimates.map((est) => est.estimate);
      return [
        scenario.contract.text,
        estimates.length ? (estimates[estimates.length - 1] * 100).toFixed(2) : 0,
      ];
    });
    return l.sort((a: (string | number)[], b: (string | number)[]) => { return (b[1] as number) - (a[1] as number); });
  }

  public getSeriesBinary() {
    const percentiles = {
      0: [],
      10: [],
      20: [],
      30: [],
      40: [],
      50: [],
      60: [],
      70: [],
      80: [],
      90: [],
    };
    const series: number[] = [];
    const data = this.question.data as IBinaryData;
    for (let i = 0; i < data.estimates.length; i++) {
      const est = data.estimates[i];
      if (est.is_initial) continue;
      let p = Math.floor(est.p_estimate * 10) * 10;
      if (p > 99) p = 90;
      percentiles[p].push(est.p_estimate);
    }
    for (const [name, data] of Object.entries(percentiles)) {
      series.push(data.length);
    }
    return [{data: series, name: 'Count'}];
  }

  public getSeriesData() {
    if (this.question.type === 'scenario') {
      const x = [{ data: this.getSeriesScenario().map((s) => s[1]), name: 'Share' }];
      return x;
    } else {
      return this.getSeriesBinary();
    }
  }

  public getSeriesCategories() {
    if (this.question.type === 'scenario') {
      return this.getSeriesScenario().map((s) => s[0]);
    } else {
      return ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90'];
    }
  }

  public update() {
    this.sessionData = { ...this.sessionDataIn };
    this.question = { ...this.questionIn };
    this.chartOptions = this.getChartOptions();
  }

  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  public getChartOptions = () => {
    return {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          barHeight: 50,
          horizontal: this.question.type === 'scenario',
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: this.question.type === 'scenario' ? 40 : 0,
        offsetY: this.question.type === 'binary' ? -40 : 0,
        formatter: (val) => {
          if (this.question.type === 'scenario') return `${val}%`;
          else return val;
        }
      },
      xaxis: {
        categories: this.getSeriesCategories(),
        type: 'category',
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          },
          formatter: (val) => {
            if (this.question.type === 'binary') {
              let high = Number(val) + 9;
              if (high === 99) high = 100;
              return `${val}-${high}%`;
            }
            else return `${val}%`;
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          }
        },
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: false
      },
      fill: {
        colors: ['#6279BE']
      },
    };
  }
}

