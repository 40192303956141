var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-wrap pa-0"},[_c('v-card',{staticClass:"pa-0 ml-n3",attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-facebook',{staticClass:"base-social",attrs:{"window-features":_vm.windowFeatures,"share-options":_vm.shareOptions,"use-native-behavior":_vm.useNativeBehavior}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/ios-glyphs/30/ffffff/facebook.png","alt":"Share on Facebook"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share on Facebook")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-gmail',{staticClass:"base-social",attrs:{"window-features":_vm.windowFeatures,"share-options":_vm.emailShareOptions,"use-native-behavior":_vm.useNativeBehavior}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/material-outlined/24/ffffff/gmail-new.png","alt":"Share using Gmail"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share via Gmail")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-linked-in',{staticClass:"base-social",attrs:{"window-features":_vm.windowFeatures,"share-options":_vm.shareOptions,"use-native-behavior":_vm.useNativeBehavior}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/ios-glyphs/30/ffffff/linkedin.png","alt":"Share on LinkedIn"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share on LinkedIn")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-telegram',{staticClass:"base-social",attrs:{"window-features":_vm.windowFeatures,"share-options":_vm.shareOptions,"use-native-behavior":_vm.useNativeBehavior}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/material-outlined/24/ffffff/telegram-app.png","alt":"Share using Telegram"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share via Telegram")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-twitter',{staticClass:"base-social",attrs:{"window-features":{"width":600,"height":540},"share-options":_vm.twitterShareOptions,"use-native-behavior":false}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/ios-glyphs/30/ffffff/twitter--v1.png","alt":"Share on Twitter"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share on Twitter")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('s-whats-app',{staticClass:"base-social",attrs:{"window-features":{"width":600,"height":540},"share-options":_vm.numberShareOptions,"use-native-behavior":false}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/material-outlined/24/ffffff/whatsapp--v1.png","alt":"Share on WhatsApp"}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share via Whatsapp")])])],1),_c('v-card',{attrs:{"elevation":"0 ml-1"}},[_c('v-tooltip',{attrs:{"max-width":"20vw","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"base-social",staticStyle:{"cursor":"pointer"}},[_c('img',_vm._g(_vm._b({staticClass:"social-img",attrs:{"src":"https://img.icons8.com/material-outlined/24/ffffff/slack-new--v1.png","alt":"Share on Slack"},on:{"click":_vm.openSlackShare}},'img',attrs,false),on))])]}}])},[_c('span',[_vm._v("Share on Slack")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }