





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { IQuestionCreate, QuestionTypes } from '@/interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  dispatchGetSessionData,
  dispatchDeleteQuestion,
  dispatchCreateQuestions,
  dispatchRecruitExternal,
  dispatchGetQuestionResponses,
} from '@/store/sessionData/actions';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchGetUserProfile } from '@/store/main/actions';
import {
  dispatchDeleteSession,
  dispatchUpdateSession,
  dispatchCopySession,
} from '@/store/sessions/actions';
import {
  readSessionData,
  readQuestionData,
  readQuestionResponses,
  readQuestionTopics,
  readSessionAIRecruitment,
  readSessionDataOpenEnded,
} from '@/store/sessionData/getters';
import SessionResponsesTab from '@/components/SessionResponsesTab.vue';
import OpenEndedQuestionResponseTab from '@/components/OpenEndedQuestionResponseTab.vue';
import NominalQuestionResponseTab from '@/components/NominalQuestionResponseTab.vue';
import NominalQuestionGraphTab from '@/components/NominalQuestionGraphTab.vue';
import BinaryTopicsTab from '@/components/BinaryTopicsTab.vue';
import ScenarioTopicsTab from '@/components/ScenarioTopicsTab.vue';
import ChatWindow from '@/components/ChatWindow.vue';
import QuestionGraphTab from '@/components/QuestionGraphTab.vue';
import EstimatesTab from '@/components/EstimatesTab.vue';
import { publicSessionLinkBase, resultsLinkBase } from '@/env';
import store from '@/store';
import { ISessionUpdate, IRecruitParams } from '@/interfaces';
import QuestionCarousel from '@/components/QuestionCarousel.vue';
import { graphColors } from '@/components/utils';
import QuestionSummaryTab from '@/components/QuestionSummaryTab.vue';
import OverviewTab from '@/components/OverviewTab.vue';
import TopicsTab from '@/components/TopicsTab.vue';
import DriversTab from '@/components/DriversTab.vue';
import SocialMediaShareButtons from '@/components/SocialMediaShare.vue';
import OrderQuestionsDialog from '@/components/OrderQuestionsDialog.vue';
import SshPre from 'simple-syntax-highlighter/dist/sshpre.cjs';
import 'simple-syntax-highlighter/dist/sshpre.css';
import RecruitExternal from '@/components/RecruitExternal.vue';
import { readHasAdminAccess, readToken } from '@/store/main/getters';
import { apiUrl } from '@/env';
import { api } from '@/api';

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import OpenEndedQuestionTopicsTab from '@/components/OpenEndedQuestionTopicsTab.vue';
import { available_languages } from '../../../i18n';
import { v4 as uuid4 } from 'uuid';
import Results from './Results.vue';
import AddBinaryQuestion from '@/components/AddBinaryQuestion.vue';
import AddScenarioQuestion from '@/components/AddScenarioQuestion.vue';
import AddOpenQuestion from '@/components/AddOpenEndedQuestion.vue';
import AddNominalQuestion from '@/components/AddNominalQuestion.vue';

@Component({
  components: {
    'add-binary-question': AddBinaryQuestion,
    'add-scenario-question': AddScenarioQuestion,
    'add-open-question': AddOpenQuestion,
    'add-nominal-question': AddNominalQuestion,
    'question-carousel': QuestionCarousel,
    'question-graph-tab': QuestionGraphTab,
    'question-summary-tab': QuestionSummaryTab,
    'overview-tab': OverviewTab,
    'responses-tab': SessionResponsesTab,
    'open-ended-question-response-tab': OpenEndedQuestionResponseTab,
    'nominal-question-response-tab': NominalQuestionResponseTab,
    'nominal-question-graph-tab': NominalQuestionGraphTab,
    'binary-topics-tab': BinaryTopicsTab,
    'scenario-topics-tab': ScenarioTopicsTab,
    'topics-tab': TopicsTab,
    'drivers-tab': DriversTab,
    'open-ended-question-topics-tab': OpenEndedQuestionTopicsTab,
    'estimates-tab': EstimatesTab,
    'recruit-external': RecruitExternal,
    'chat-window': ChatWindow,
    'social-media-share-buttons': SocialMediaShareButtons,
    'ssh-pre': SshPre,
    'order-questions': OrderQuestionsDialog,
    'vue-dropzone': vue2Dropzone,
    'results': Results,
  },
})
export default class Session extends Vue {
  public showTestChat = false;
  public languages = available_languages;
  public selectedCountryLang = 'EN-GB';
  public questionDialog = false;
  public chosenLanguage = 'english';
  public chatWindowReload = 0;
  public valid = true;
  public title = '';
  public description = '';
  public redirectUrl = '';
  public redirectUrlUseParams = false;
  public collect_email = false;
  public collect_email_text = '';
  public completion_code = false;
  public completion_code_text = '';
  public completion_code_unique = false;
  public disqualifiedShouldRedirect = false;
  public disqualifiedRedirectUrl = '';
  public screeningText = '';
  public debounce = 0;
  public resultsLinkBase = resultsLinkBase;
  public selectedState = '';
  public dropzoneUploading = false;
  public recruitDialog = false;
  public launchDialog = false;
  public deleteStudyDialog = false;
  public copyStudyDialog = false;
  public deleteQuestionDialog = false;
  public questionToBeDeleted = 0;
  public lucidDialog = false;
  public confirmCloseDialog = false;
  public settingsDialog = false;
  public activeStudyDialog = false;
  public draftStudyDialog = false;
  public orderQuestionsDialog = false;
  public slackDialog = false;
  public snackbar = false;
  public snackbar_text = 'Copied to clipboard.';
  public sessionId = '';
  public polling = 0;
  public pausedPolling = false;
  public selectedTheme = 'dark';
  public chatStyle?: object = undefined;
  public chatLogo = '';
  public chatLogoEnabled = false;
  public recentlyCompleted = false;
  public showCopied = {
    publicLink: false,
    resultsLink: false,
    htmlHeadElement: false,
    htmlChatElement: false,
    slackShare: false,
  };
  public slackMsg = '';
  public loading = true;
  public panel: number[] = [];
  public ai_recruitment = {};
  public active_view = 1;
  public themes = {
    'green': {
      'chat-theme': 'green',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#004d2d',
      'chat-message-bg-color': '#197a56',
      'chat-message-bg-color-me': '#50aa83',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#004d2d',
      'chat-bg-color-input': '#50aa83',
      'chat-color-placeholder': '#004d2d',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'light': {
      'chat-theme': 'light',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#494949',
      'chat-message-bg-color': '#757575',
      'chat-message-bg-color-me': '#a4a4a4',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#494949',
      'chat-bg-color-input': '#a4a4a4',
      'chat-color-placeholder': '#494949',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'blue': {
      'chat-theme': 'blue',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#0069c0',
      'chat-message-bg-color': '#2196f3',
      'chat-message-bg-color-me': '#6EC6FF',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#0069c0',
      'chat-bg-color-input': '#6EC6FF',
      'chat-color-placeholder': '#0069c0',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'dark': {
      'chat-theme': 'dark',
      'chat-content-bg-color': '#131415',
      'chat-header-bg-color': '#181A1B',
      'chat-message-bg-color': '#222429',
      'chat-message-bg-color-me': '#346769',
      'chat-color-button': '#ffffff',
      'chat-footer-bg-color': '#181A1B',
      'chat-bg-color-input': '#202223',
      'chat-color-placeholder': '#596269',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    }
  }
  public data() {
    const active = true;
    const tab = [];
    const expanded = [[]];

    return {
      active,
      tab,
      expanded,
    };
  }

  async settingsLogoUploadSuccess() {
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    commitAddNotification(this.$store, {
      content: 'Image Uploaded Successfully.',
      color: 'success'
    });
    this.chatLogo = this.sessionData.chat_logo ?? '';
  }

  uploadComplete(response) {
    this.dropzoneUploading = false;
  }

  beforeLogoUpload(file, xhr, formData) {
    formData.append('session_id', this.sessionId);
    this.dropzoneUploading = true;
  }

  public settingsDropzoneOptions = {
    url: `${apiUrl}/api/v1/sessions/upload_chat_logo`,
    headers: {
      Authorization: `Bearer ${readToken(this.$store)}`,
    },
    paramName: function (n) {
      return 'file';
    },
    includeStyling: false,
    previewsContainer: false,
    thumbnailWidth: 250,
    thumbnailHeight: 140,
    parallelUploads: 20,
    success: function (file, response) {
      // Success
    },
  };

  public setChatTheme(theme: string) {
    this.selectedTheme = theme;
    this.chatStyle = this.sessionData.chat_style ?? this.themes['dark'];
    this.chatStyle['chat-theme'] = this.selectedTheme;
    this.submit();
  }

  public async close_settings_dialog() {
    this.settingsDialog = false;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public addingQuestionsAllowed() {
    return (
      this.selectedState === 'draft'
      && !this.loading
      && !this.sessionData.lucid_survey_nr
      && this.chosenLanguage == 'english'
    );
  }

  public forceRerender() {
    this.chatWindowReload += 1;
  }

  public toggleSnackbar() {
    this.snackbar = !this.snackbar;
  }

  @Watch('slackDialog')
  onSlackDialogChange(val: boolean, oldVal: boolean) {
    if (oldVal == true) {
      this.slackMsg = `Join my Masspredict study!\n${this.studyLink}`;
    }
    else {
      this.slackDialog = val;
    }
  }

  public idToCountryLangId = {
    8: 'UK',
    9: 'US',
    1: 'CN',
    23: 'SE',
  }

  public widgetSnippet() {
    return `&#x3C;iframe style=&#x22;border: none;&#x22; src=&#x22;${this.chatLink}&#x22; width=&#x22;100%&#x22; height=&#x22;100%&#x22;&#x3E;&#x3C;/iframe&#x3E;`;
  }

  public htmlDecoder(html) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  }

  public toClipboard(str, field) {
    const el = document.createElement('textarea');
    el.addEventListener('focusin', e => e.stopPropagation());
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toggleShowCopied(field, true);
    setTimeout(() => {
      this.toggleShowCopied(field, false);
    }, 1000);
  }

  public toggleShowCopied(field: string, value: boolean) {
    switch (field) {
      case 'public-link':
        this.showCopied.publicLink = value;
        break;
      case 'results-link':
        this.showCopied.resultsLink = value;
        break;
      case 'html-head-element':
        this.showCopied.htmlHeadElement = value;
        break;
      case 'html-chat-element':
        this.showCopied.htmlChatElement = value;
        break;
      case 'slack-share':
        this.showCopied.slackShare = value;
        break;
    }
  }

  public async mounted() {
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    this.panel = [...Array(this.sessionData.questions_with_data.length).keys()];
    if (this.sessionAIData) {
      this.agentsRunning = this.sessionAIData.active;
    }
    this.polling = setInterval(async () => {
      if (!this.pausedPolling && this.sessionData.id && (this.sessionData.state == 'active' || this.recentlyCompleted)) {
        await dispatchGetSessionData(store, { id: this.$route.params.id });
      }
      if (this.sessionAIData) {
        this.agentsRunning = this.sessionAIData.active;
        this.recruitButtonText = 'Recruit';
      }
    }, 10000);
    this.reset();
    this.loading = false;
  }

  public beforeDestroy() {
    clearInterval(this.polling);
  }

  get sessionData() {
    return readSessionData(this.$store);
  }

  get sessionDataOpenEnded() {
    return readSessionDataOpenEnded(this.$store);
  }

  get sessionAIData() {
    return readSessionAIRecruitment(this.$store);
  }

  get recruitmentInQueue() {
    if (!this.sessionAIData || !this.sessionAIData.in_queue) {
      return false;
    }
    return this.sessionAIData.in_queue;
  }

  get recruitmentQueuePosition() {
    if (!this.sessionAIData || !this.sessionAIData.queue_position) {
      return 0;
    }
    return this.sessionAIData.queue_position;
  }
  
  get agentsRunningProgress() {
    if (!this.sessionAIData || !this.sessionAIData.progress) {
      return 0;
    }
    return this.sessionAIData.progress.toFixed(1);
  }

  get studyResultsLink() {
    return window.location.origin + '/results/' + this.$route.params.id;
  }

  get studyLink() {
    return publicSessionLinkBase + this.$route.params.id;
  }

  get chatLink() {
    return publicSessionLinkBase + 'chat/' + this.$route.params.id;
  }

  // Auto save on type
  public debounceSearch(event) {
    clearTimeout(this.debounce);
    this.pausedPolling = true;
    this.debounce = setTimeout(() => {
      this.submit();
      this.pausedPolling = false;
    }, 1000);
  }

  // Set session info on page load
  public reset() {
    this.$validator.reset();
    if (this.sessionData) {
      this.title = this.sessionData.title;
      this.description = this.sessionData.description;
      this.redirectUrl = this.sessionData.redirect_url;
      this.redirectUrlUseParams = this.sessionData.redirect_url_use_params;
      this.collect_email = this.sessionData.collect_email;
      this.collect_email_text = this.sessionData.collect_email_text || 'Thank you for completing the study. Please enter your email to receive your completion reward.';
      this.selectedState = this.sessionData.state;
      this.sessionId = this.$route.params.id;
      this.slackMsg = `Join my Masspredict study!\n${this.studyLink}`;
      this.chosenLanguage = this.languages[this.sessionData.display_lang];
      this.selectedTheme = this.sessionData.chat_style ? this.sessionData.chat_style['chat-theme'] : 'dark';
      this.chatLogo = this.sessionData.chat_logo ?? '';
      this.chatLogoEnabled = this.sessionData.chat_logo_enabled;
      this.$i18n.locale = this.sessionData.display_lang;
      this.collect_email = this.sessionData.collect_email;
      this.completion_code = this.sessionData.completion_code;
      this.completion_code_text = this.sessionData.completion_code_text;
      this.completion_code_unique = this.sessionData.completion_code_unique;
      this.disqualifiedShouldRedirect = this.sessionData.disqualified_should_redirect;
      this.disqualifiedRedirectUrl = this.sessionData.disqualified_redirect_url;
      this.screeningText = this.sessionData.screening_text;
      this.chatStyle = this.sessionData.chat_style;
    }
  }

  // Submit page changes to update session
  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.disqualifiedShouldRedirect && !this.disqualifiedRedirectUrl) {
        commitAddNotification(this.$store, {
          content: 'A redirect URL is required if disqualification is turned on.',
          color: 'yellow darken-3'
        });
        return;
      }
      const updatedSession: ISessionUpdate = {};
      if (
        this.title ||
        this.description ||
        this.redirectUrl ||
        this.selectedState
      ) {
        updatedSession.title = this.title;
        updatedSession.description = this.description;
        updatedSession.redirect_url = this.redirectUrl;
        updatedSession.redirect_url_use_params = this.redirectUrlUseParams;
        updatedSession.state = this.selectedState;
        updatedSession.collect_email = this.collect_email;
        updatedSession.collect_email_text = this.collect_email_text;
        updatedSession.chat_logo_enabled = this.chatLogoEnabled;
        updatedSession.completion_code = this.completion_code;
        updatedSession.completion_code_text = this.completion_code_text;
        updatedSession.completion_code_unique = this.completion_code_unique;
        updatedSession.disqualified_should_redirect = this.disqualifiedShouldRedirect;
        updatedSession.disqualified_redirect_url = this.disqualifiedRedirectUrl;
        updatedSession.screening_text = this.screeningText;
        updatedSession.chat_style = this.chatStyle;
        await dispatchUpdateSession(this.$store, {
          id: this.sessionData!.id,
          session: updatedSession,
        });
        if (this.sessionData.state == 'active' && this.selectedState == 'complete') {
          this.recentlyCompleted = true;
        }
        console.log(this.sessionData.state, this.selectedState);
        await dispatchGetSessionData(store, { id: this.$route.params.id });
        console.log(this.sessionData.state, this.selectedState);
      }
    }
  }

  public async changeStudyLang(lang: string) {
    this.chosenLanguage = this.languages[lang];
    await dispatchUpdateSession(this.$store, {
      id: this.sessionData!.id,
      session: {
        display_lang: lang
      },
    });
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    this.reset();
  }

  public async changeAdminLang(lang: string) {
    this.chosenLanguage = this.languages[lang];
    await dispatchUpdateSession(this.$store, {
      id: this.sessionData!.id,
      session: {
        admin_lang: lang
      },
    });
    await dispatchGetSessionData(store, { id: this.$route.params.id });
    this.reset();
  }

  public async createQuestion(question) {
    await dispatchCreateQuestions(this.$store, {
      sessionId: this.$route.params.id,
      questions: [question],
    });
  }

  public async removeQuestion(index) {
    this.deleteQuestionDialog = false;
    await dispatchDeleteQuestion(this.$store, {
      sessionId: this.$route.params.id,
      questionId: this.sessionData.questions_with_data[index].id,
    });
    this.reset();
  }

  public async downloadParticipants() {
    const token = readToken(this.$store);
    const response = await api.downloadSessionParticipantEmailsCSV(token, this.sessionData!.id);
    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'participants.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    }
  }

  public async agreeToLaunch() {
    this.selectedState = 'active';
    this.launchDialog = false;
    this.recruitDialog = false;
    await this.submit();
  }

  public async completeStudy() {
    this.selectedState = 'complete';
    this.confirmCloseDialog = false;
    await this.submit();
  }

  public verifyState() {
    if (this.selectedState == 'active' || this.selectedState == 'complete') {
      return true;
    }
  }

  public verifyActiveState() {
    if (this.selectedState == 'active') {
      return true;
    }
  }

  public async deleteSession() {
    await dispatchDeleteSession(this.$store, this.$route.params.id);
    await this.$router.push('/main/user/session/all');
  }

  public async copySession() {
    await dispatchCopySession(this.$store, this.$route.params.id);
    await this.$router.push('/main/user/session/all');
  }

  public questionData(questionId: string) {
    return readQuestionData(this.$store)(questionId);
  }

  public questionResponseCount(questionId: string) {
    const responses = readQuestionResponses(this.$store)(questionId);
    return responses ? responses.participants_count : 0;
  }

  public questionTopics(questionId: string) {
    return readQuestionTopics(this.$store)(questionId);
  }

  public colors() {
    return graphColors;
  }

  public async recruitLucid(params: IRecruitParams) {
    await dispatchRecruitExternal(
      this.$store,
      { sessionId: this.$route.params.id, params: params }
    );
    await dispatchGetUserProfile(this.$store);
    await dispatchGetSessionData(store, { id: this.$route.params.id });
  }

  // Format completion time
  public getFormattedCompletionTime(time: number) {
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    const minutes = Math.floor(time / 60);
    const seconds = Math.trunc(time % 60);
    return hours.toString().concat(
      'h | ', minutes.toString(), ' min | ', seconds.toString(), ' sec'
    );
  }

  public getFormattedDateTime(date_and_time) {
    return date_and_time.substr(0, 16).replace('T', ', ');
  }

  public getFormattedConversion(started, finished) {
    const conversion = Math.round(finished / started * 100);
    return conversion.toString().concat('%');
  }

  onClickCountryFlag(lang) {
    this.selectedCountryLang = lang;
    this.lucidDialog = true;
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name == 'xs' ? { ['small']: true } : {};
  }

  public async responseTabClicked(question_index: number) {
    await dispatchGetQuestionResponses(store, { questionId: this.sessionData.questions_with_data[question_index].id, pageLength: 5, pageNumber: 1, search: '' });
  }

  public setRandomCompletionCode() {
    this.completion_code_text = uuid4();
    this.submit();
  }

  public agentCount = 1;
  public recruitAgentClicked = false;
  public agentsRunning = false;
  public killAgentsButtonText = 'Kill Agents';
  public recruitButtonText = 'Recruit';
  public killAgentsClicked = false;
  public addQuestionDialog = false;
  public carousel = 0;
  public selectedType: QuestionTypes = QuestionTypes.binary;
  public studyLoading = false;
  public questionLoading = false;
  public generateQuestionButtonText = 'Generate Question';

  public types = [
    {
      text: 'Binary question',
      value: QuestionTypes.binary,
      bool: false
    },
    {
      text: 'Scenario question',
      value: QuestionTypes.scenario,
      bool: false
    },
    {
      text: 'Open question',
      value: QuestionTypes.open,
      bool: false
    },
  ];

  public async generateSingleQuestion() {
    this.generateQuestionButtonText = 'Generating...';
    const token = readToken(this.$store);
    this.questionLoading = true;
    try {
      const questions: IQuestionCreate[] = [];
      for (const question of this.sessionData!.questions_with_data) {
        questions.push({
          id: question.id,
          title: question.title,
          context: question.context,
          type: question.type,
          contracts: question.contracts,
          randomize_qs: false,
        });
      }
      const response = await api.generateSingleQuestion(token, { title: this.title, questions: questions });
      const generatedQuestion = JSON.parse(response.data);
      this.createQuestion({
        'title': generatedQuestion.title,
        'context': '',
        'type': generatedQuestion.type,
        'contracts': generatedQuestion.contracts,
        'randomize_qs': false
      });
    }
    catch (error) {
      commitAddNotification(this.$store, { content: 'Failed to generate question', color: 'error' });
    }
    this.generateQuestionButtonText = 'Generate Question';
    this.questionLoading = false;
    this.toggleQuestionDialogue(false);
  }

  public resetCarousel() {
    this.carousel = 0;
  }

  public toggleQuestion(index: number) {
    if (index == 0) {
      this.selectedType = QuestionTypes.binary;
    } else if (index == 1) {
      this.selectedType = QuestionTypes.scenario;
    } else if (index == 2) {
      this.selectedType = QuestionTypes.open;
    } else if (index == 3) {
      this.selectedType = QuestionTypes.nominal;
    }
    this.moveCarousel(true);
  }

  public moveCarousel(value = true) {
    if (this.carousel >= 0) {
      if (value) {
        this.carousel++;
      } else { this.carousel--; }
    }
  }

  public toggleQuestionDialogue(value: boolean) {
    this.addQuestionDialog = value;
    this.resetCarousel();
  }

  public async recruitAI(e) {
    if (this.agentsRunning) {
      return 0;
    }
    if (this.recruitAgentClicked) {
      const token = readToken(this.$store);
      const response = await api.recruitAI(token, this.sessionData!.id, this.agentCount);
      this.recruitButtonText = 'Agents running';
      this.recruitAgentClicked = false;
      this.agentsRunning = true;
    } else {
      this.recruitButtonText = 'Click to confirm';
      this.recruitAgentClicked = true;
    }
    setTimeout(() => {
      if (!this.agentsRunning) {
        this.recruitButtonText = 'Recruit';
        this.recruitAgentClicked = false;
      }
    }, 2000);
  }

  public async forceStopAgents() {
    if (!this.agentsRunning) return 0;
    if (this.killAgentsClicked) {
      const token = readToken(this.$store);
      const response = await api.killAgents(token, this.sessionData!.id, this.agentCount);
      this.agentsRunning = false;
      this.killAgentsButtonText = 'Kill Agents';
      this.killAgentsClicked = false;
    } else {
      this.killAgentsButtonText = 'Click to confirm';
      this.killAgentsClicked = true;
    }
    setTimeout(() => {
      this.killAgentsButtonText = 'Kill Agents';
      this.killAgentsClicked = false;
    }, 2000);
  }

  public async switchtoDetails() {
    this.$vuetify.goTo(0);
    setTimeout(() => {
      this.active_view = 0;
    }, 600);
  }
}

