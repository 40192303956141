


























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { IQuestionData, INominalQuestion, IQuestionTopicsData, ISessionData} from '@/interfaces';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class DriversTab extends Vue {
  @Prop() public questionIn!: IQuestionData;
  @Prop() public topicsDataIn!: IQuestionTopicsData|null;
  @Prop() public nominalQuestionsIn!: INominalQuestion[] | undefined;
  @Prop() public sessionDataIn!: ISessionData;

  @Watch('questionIn') onQuestionInChanged() {
    this.question = this.questionIn;
  }

  @Watch('nominalQuestionsIn') nominalQuestionsInChanged() {
    this.nominal_questions = this.nominalQuestionsIn;
  }

  @Watch('topicsDataIn') onTopicsDataInChanged() {
    this.topics_data = this.topicsDataIn;
  }

  @Watch('sessionDataIn') sessionDataInChanged() {
    this.sessionData = this.sessionDataIn; 
  }

  public question = this.questionIn;
  public sessionData = this.sessionDataIn;
  public topics_data: IQuestionTopicsData|null = null;
  public nominal_questions = this.nominalQuestionsIn;
  public nominal_filters: number[][]  = [];
  public filtersLoading = false;

  mounted() {
    this.question = this.questionIn;
    this.topics_data = this.topicsDataIn;
    this.nominal_questions = this.nominalQuestionsIn;
    this.clearFilter();
  }

  get selectedState() {
    return this.sessionData.state;
  }

  public clearFilter() {
    this.nominal_filters = [];
    for (const x in this.nominal_questions) {
      this.nominal_filters.push([]);
    }
  }

  public async applyFilter() {
    if (this.nominal_questions === undefined) return;
    const filters: { question_id: string; contracts: string[] }[] = [];
    for (let i = 0; i < this.nominal_filters.length; i++) {
      const contracts: string[] = [];
      for(const filter of this.nominal_filters[i]) {
        contracts.push(this.nominal_questions[i].contracts[filter].contract_uuid);
      }
      filters.push(
        {
          'question_id': this.nominal_questions[i].id,
          'contracts': contracts
        }
      );
    }
    this.filtersLoading = true;
    const response = await api.checkTopicClassificationProgress(readToken(this.$store), this.question.id, filters);
    this.filtersLoading = false;
    if (response.status === 200) {
      this.topics_data = response.data.data;
    }
  }

  labels(index: number) {
    if (this.topics_data)
      return this.topics_data[`drivers_${index}`].map((item: number) => item[0]);
    else
      return [];
  }

  label_values(index: number) {
    if (this.topics_data)
      return this.topics_data[`drivers_${index}`].map((item: number) => Math.round(item[1] * 100));
    else 
      return [];
  }

  public getSeriesData(index: number) {
    return [{data: this.label_values(index), name: 'Share'}];
  }

  public getChartOptions (index: number) {
    return {
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (value: number) => {
          return `${value}%`;
        },
        offsetX: 30
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        categories: this.topics_data ? this.labels(index) : [],
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          },
          formatter: (val) => {
            return `${val}%`;
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          }
        }
      },
      grid: {
        show: false
      },
      fill: {
        colors: ['#6279BE']
      },
    };
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name == 'xs' ? { ['small']: true } : {};
  }

  get small_breakpoint() {
    return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm';
  }
}
