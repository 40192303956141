
















































































































import { Component, Vue } from 'vue-property-decorator';
import { QuestionTypes} from '@/interfaces';

@Component
export default class AddNominalQuestion extends Vue {

  public addOption() {
    this.contracts.push('');
  }

  public exitDisplay() {
    this.$emit('togglePredictionDialogue', false);
    this.resetQuestion();
  }

  public currentQuestion = {
    context: '',
    title: '',
    type: QuestionTypes.nominal,
    contracts: [''],
    randomize_qs: false,
  };
  

  beforeDestroy() { this.$validator.pause(); }

  public async addQuestion() {
    if (await this.$validator.validateAll()) {
      this.currentQuestion.contracts = [
        ...new Set(
          this.contracts.map(x => x.trim())
        ),
      ];
      if (this.currentQuestion.contracts.length > 1) {
        this.$emit('pushQuestions', this.currentQuestion);
        this.$emit('togglePredictionDialogue', false);
        this.$emit('resetCarousel');
        this.resetQuestion();
        await this.$validator.reset();
      } else {
        const field = this.$validator.fields.find({ name: '0' });
        if (field) {
          this.$validator.errors.add({
            field: '0',
            msg:
              'You must have at least 2 unique response options. Please fix them to continue.',
          });
        }
      }
    }
  }

  public backToMenu() {
    this.resetQuestion();
    this.$validator.reset();
    this.$emit('resetCarousel');
  }

  public contracts: string[] = ['',''];

  public resetQuestion() {
    this.currentQuestion = {
      context: '',
      title: '',
      type: QuestionTypes.nominal,
      contracts: ['', ''],
      randomize_qs: false,
    };
  }
  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }
}

