<template>
  <v-dialog v-model="showChat" content-class="position-relative" name="chat-dialog" max-width="450px" :fullscreen="fullScreen">
    <v-btn style="position: absolute; z-index: 10; background: none; width: 30px; right: 0;" elevation="0" @click="showChat = false">
      <v-icon large> {{ "mdi-close" }} </v-icon>
    </v-btn>
    <v-card height="600px" style="overflow: hidden">
      <iframe class="chat-widget-iframe" style="border: none;" :src="link"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue';
import { environment } from '@/env';

export default Vue.component('ChatWindow', {
  props: {
    value: Boolean,
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      session_id: this.$route.params.id,
      env: environment,
      channel: 'tryout',
      fullScreen: this.$vuetify.breakpoint.name === 'xs',
    };
  },
  computed: {
    showChat: {
      get () {
        if (this.value) {
          this.reset();
        }
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
  },
  methods: {
    close() {
      this.$emit('closed', true);
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    chatWindowHeight() {
      if (this.isMobile) {
        return window.innerHeight;
      } else {
        return 600;
      }
    }
  },
});
</script>

<style>
@media screen and (max-width: 959px) {
  #inputText {
    width: 400px;
  }
}
.elevation-12 {
  margin-top: 30px;
}
#toolbar-line {
  height: 20px;
  background-color: black;
}
#circle-group {
  padding-left: 5px;
}
.circle {
  margin-bottom: 3px;
  margin-right: 4px;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeInOptions {
  0% {opacity:0;}
  100% {opacity:0.8;}
}
@-moz-keyframes fadeInOptions {
  0% {opacity:0;}
  100% {opacity:0.8;}
}
@-webkit-keyframes fadeInOptions {
  0% {opacity:0;}
  100% {opacity:0.8;}
}
@-o-keyframes fadeInOptions {
  0% {opacity:0;}
  100% {opacity:0.8;}
}
@-ms-keyframes fadeInOptions {
  0% {opacity:0;}
  100% {opacity:0.8;}
}
.v-application p {
  margin-bottom: 7px;
  margin-top: 6px;
}
.chat-widget-iframe {
  width: 450px;
  max-width: 100vw;
  height: 600px;
  max-height: 100vh;
}
@media screen and (max-width: 600px) {
  .chat-widget-iframe {
    height: 100vh;
  }
}

.position-relative {
  position: relative;
}

</style>
