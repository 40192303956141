





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { IQuestionData, IQuestionTopicsData, INominalQuestion, ISessionData, IQuestionWithTopics } from '@/interfaces';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import { commitSetQuestionTopicsDrivers } from '@/store/sessionData/mutations';
import { commitAddNotification } from '@/store/main/mutations';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class TopicsTab extends Vue {
  @Prop() public questionIn!: IQuestionData;
  @Prop() public topicsDataIn!: IQuestionWithTopics | null;
  @Prop() public publicViewIn!: boolean;
  @Prop() public nominalQuestionsIn!: INominalQuestion[] | undefined;
  @Prop() public sessionDataIn!: ISessionData;

  @Watch('questionIn') onQuestionInChanged() {
    this.question = this.questionIn;
  }

  @Watch('topicsDataIn') onTopicsDataInChanged() {
    this.topics_data = this.topicsDataIn?.topics == undefined ? null : this.topicsDataIn.topics;
  }

  @Watch('publicViewIn') onPublicViewInChanged() {
    this.publicView = this.publicViewIn;
  }

  @Watch('nominalQuestionsIn') nominalQuestionsInChanged() {
    this.nominal_questions = this.nominalQuestionsIn;
  }

  @Watch('sessionDataIn') sessionDataInChanged() {
    this.sessionData = this.sessionDataIn;
  }

  public question = this.questionIn;
  public sessionData = this.sessionDataIn;
  public nominal_questions = this.nominalQuestionsIn;
  public debounce_list = [0, 0, 0, 0, 0];
  public pausedPolling = false;
  public publicView = false;
  public shuffleSearch = '';
  public shuffleResult = '';
  public loading = false;
  public topicSuggestionRunning = false;
  public progress = 0;
  public topics_data: IQuestionTopicsData | null = null;
  public topic_labels: { label: string; count: number | null; loading: boolean }[] = [
    { label: '', count: null, loading: false },
    { label: '', count: null, loading: false },
    { label: '', count: null, loading: false },
    { label: '', count: null, loading: false },
    { label: '', count: null, loading: false },
  ];
  public selectedAgeValues = [];
  public selectedGenderValues = [];
  public total_coverage = 0;

  mounted() {
    this.question = this.questionIn;
    this.topics_data = this.topicsDataIn?.topics == undefined ? null : this.topicsDataIn.topics;
    this.progress = this.topicsDataIn?.progress || 0;
    this.publicView = this.publicViewIn;
    this.nominal_questions = this.nominalQuestionsIn;
    if (this.topics_data) {
      this.topic_labels = [];
      this.debounce_list = [];
      for (const label of this.topics_data.labels) {
        this.topic_labels = this.topic_labels.concat({ label: label.label, count: label.count, loading: false });
        this.debounce_list.push(0);
      }
    }
    this.getQuestionTotalCoverage();
    this.checkClassifierStatus();
  }

  get selectedState() {
    return this.sessionData.state;
  }

  public async updateLabel(index: number) {
    try {
      const response = await api.getLabelCoverageInReasons(readToken(this.$store), this.question.id, this.topic_labels[index].label);
      if (response.status == 200) {
        this.topic_labels[index].loading = false;
        this.topic_labels[index].count = response.data;
        this.getQuestionTotalCoverage();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getQuestionTotalCoverage() {
    const candidate_labels = this.topic_labels.reduce((prev: string[], current) => { if (current.label) prev.push(current.label); return prev; }, []);
    if (candidate_labels.length == 0) return;
    const resp = await api.getTotalLabelCoverageInReasons(readToken(this.$store), this.question.id, candidate_labels);
    if (resp.status == 200) {
      this.total_coverage = resp.data;
    }
  }

  public async shuffle() {
    try {
      const response = await api.getShuffledReason(readToken(this.$store), this.question.id, this.shuffleSearch);
      if (response) {
        this.shuffleResult = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async checkClassifierStatus() {
    if (this.progress < 0 || this.topics_data) {
      this.loading = false;
      return;
    }
    let progress = 0;
    let request_counts = 0;
    for (; ;) {
      try {
        const response = await api.checkTopicClassificationProgress(readToken(this.$store), this.question.id, []);
        if (response) {
          progress = response.data.progress;
          if (progress < 0) {
            this.loading = false;
            break;
          } else {
            this.loading = true;
            if (progress == 100) {
              commitSetQuestionTopicsDrivers(this.$store, response.data);
              this.topics_data = response.data.topics;
              this.loading = false;
              break;
            } else {
              this.progress = progress;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      request_counts++;

      if (request_counts > 500) {
        this.loading = false;
        commitAddNotification(this.$store, {
          content: 'NLP Error. Please try again.',
          color: 'error'
        });
        break;
      }
      await new Promise(r => setTimeout(r, 5000));
    }
  }

  public getSeriesData(index: number) {
    return [{ data: this.topics_data ? Object.values(this.topics_data[`topics_${index}`]) : [], name: 'Share' }];
  }

  public clearLabel(index: number) {
    this.topic_labels[index] = { label: '', count: null, loading: false };
  }

  public addLabelField() {
    if (this.topic_labels.length < 10) {
      this.topic_labels.push({ label: '', count: null, loading: false });
      this.debounce_list.push(0);
    }
  }

  public async labelUpdated(index: number) {
    if (this.topic_labels[index].label.length < 3) {
      this.topic_labels[index].count = null;
      return;
    }
    clearTimeout(this.debounce_list[index]);
    this.pausedPolling = true;
    this.topic_labels[index].loading = true;
    this.debounce_list[index] = setTimeout(() => {
      this.updateLabel(index);
      this.pausedPolling = false;
    }, 1000);
  }

  public getChartOptions(index: number) {
    return {
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: 30
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        categories: this.topics_data ? Object.keys(this.topics_data[`topics_${index}`]) : [],
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            colors: 'white',
            fontWeight: 200,
          }
        }
      },
      grid: {
        show: false
      },
      fill: {
        colors: ['#6279BE']
      },
    };
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name == 'xs' ? { ['small']: true } : {};
  }

  get small_breakpoint() {
    return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm';
  }
}
