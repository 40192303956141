import { render, staticRenderFns } from "./QuestionSummaryTab.vue?vue&type=template&id=6ebbbc5c&scoped=true"
import script from "./QuestionSummaryTab.vue?vue&type=script&lang=ts"
export * from "./QuestionSummaryTab.vue?vue&type=script&lang=ts"
import style0 from "./QuestionSummaryTab.vue?vue&type=style&index=0&id=6ebbbc5c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ebbbc5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VIcon,VRow,VTabItem,VTooltip})
