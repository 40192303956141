var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{staticClass:"pt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.topics,"single-expand":true,"item-key":"main_centroid","show-expand":""},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.headerTooltips[header.value])?_c('v-tooltip',{key:h.value,attrs:{"top":"","max-width":"15vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.headerTooltips[header.value])+" ")])]):_vm._e()]}}}),{key:"item.dominant_estimate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.estimateToTextMap[item.dominant_estimate])+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('h4',{staticClass:"mt-2"},[_vm._v("Other topics:")]),_c('ul',{staticClass:"mb-2"},_vm._l((item.sub_centroids),function(topic,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(topic))])}),0)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }